
import { IonContent, IonPage } from '@ionic/vue';
import { defineComponent } from 'vue';
import MessagesHq from '@/components/Messages/MessagesHqModal.vue';

export default defineComponent({
    name: 'Notifications',

    components: {
        MessagesHq,
        IonContent,
        IonPage,
    },
});
